import { Box, Heading, Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainModel from "../../../../components/Models/main-model";
import { closeStudentReportModel } from "../../../../modules/admin-modules/models/Actions";

import { Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { studentPerformanceRequest } from "../../../../modules/admin-modules/reports/Actions";
const StudentReport = ({ isAdd, student_id }) => {
  const userData = useSelector((state) => state.reports.performance);
  const isLoading = useSelector((state) => state.reports.isLoading);

  const attended = userData?.attendance;

  const homeworks = userData?.homeworks;
  const exams = userData?.exams;
  const payment = userData?.payment;
  const last_videos = userData?.last_videos;
  const dispatch = useDispatch();

  useEffect(() => {
    if (student_id) dispatch(studentPerformanceRequest(student_id));
  }, [student_id]);

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        تقرير الطالب
      </Heading>
    </Box>
  );

  const openUserData = useSelector((state) => state.model.isOpen18);

  const closeFunc = () => {
    dispatch(closeStudentReportModel());
  };
  return (
    <MainModel
      header={header}
      closeFunc={closeFunc}
      openVar={openUserData}
      buttonTitle={null}
      buttonFunc={null}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer>
          <Table variant="striped">
            <Tbody>
              <Tr>
                <Td fontWeight="600">Homeworks </Td>
                <Td>
                  {homeworks?.length > 0 ? (
                    homeworks?.map((data, index) => {
                      return (
                        <Td borderBottom="none" key={index} width="240px">
                          {data?.name}
                        </Td>
                      );
                    })
                  ) : (
                    <Td borderBottom="none">No Data Available</Td>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="600">Payment </Td>
                <Td>
                  {payment?.length > 0 ? (
                    payment?.map((data, index) => {
                      return (
                        <Td borderBottom="none" key={index} width="240px">
                          {data?.payment_subject?.name}
                        </Td>
                      );
                    })
                  ) : (
                    <Td borderBottom="none">No Data Available</Td>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="600">Attended </Td>
                <Td>
                  {attended?.length > 0 ? (
                    attended?.map((data, index) => {
                      return (
                        <Td borderBottom="none" key={index} width="240px">
                          {data?.lecture?.title}
                        </Td>
                      );
                    })
                  ) : (
                    <Td borderBottom="none">No Data Available</Td>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="600">Videos </Td>
                <Td>
                  {last_videos?.length > 0 ? (
                    last_videos?.map((data, index) => {
                      return (
                        <Td borderBottom="none" key={index} width="240px">
                          {data?.video?.title}
                        </Td>
                      );
                    })
                  ) : (
                    <Td borderBottom="none">No Data Available</Td>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="600">Exams </Td>

                <Td>
                  {exams?.length > 0 ? (
                    exams?.map((data, index) => {
                      return (
                        <Td borderBottom="none" key={index} width="240px">
                          {data?.name}
                        </Td>
                      );
                    })
                  ) : (
                    <Td borderBottom="none">No Data Available</Td>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="600">Exams Total Data</Td>
                <Td>
                  <Td borderBottom="none" width="240px">
                    Total Questions :{" "}
                    {exams?.[0]?.student_exam?.[0]?.total_exams_questions}
                  </Td>
                  <Td borderBottom="none" width="240px">
                    Total Right Questions :
                    {exams?.[0]?.student_exam?.[0]?.total_right_answers}
                  </Td>
                  <Td borderBottom="none" width="240px">
                    Percentage : {exams?.[0]?.student_exam?.[0]?.percentage}%
                  </Td>
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="600">Payment Exception</Td>
                <Td fontWeight="600">{userData?.payment_exception?.name} </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </MainModel>
  );
};

export default StudentReport;
