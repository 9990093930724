import { Box, Image } from "@chakra-ui/react";
import React from "react";
import StudentWrapper from "../../Auth/StudentWrapper";
import Loading from "../../UI/loading";
import Footer from "./footer";
import Navbar from "./navbar";

const Layout = ({ children, color, bg, register = true, type, isLoading }) => {
  return (
    <StudentWrapper roles={["Student"]}>
      <Box
        w="100%"
        h="100%"
        minH="100vh"
        className="public"
        overflowX="hidden"
        justifyContent="space-between"
      >
        <Box h="7%">
          <Navbar color={color} bg={bg} register={register} type={type} />
        </Box>
        <Image
          src={require("../../../assets/Vector.jpg")}
          position="absolute"
          right="-80px"
          top="20%"
          zIndex="-1"
          display={{ base: "none", md: "block" }}
        />

        {isLoading ? <Loading /> : <Box pb="100px">{children} </Box>}

        <Footer />
      </Box>
    </StudentWrapper>
  );
};

export default Layout;
