import React, { useEffect } from "react";
import Header from "../../components/student/Header/index";
import RegisterComp from "../../containers/student/home/registerComp";

import LatestCourses from "../../containers/student/home/latestCourses";

import { useDispatch, useSelector } from "react-redux";
import PublicLayout from "../../components/public/Layout/layout";
import RecordesComp from "../../containers/student/home/recordes";
import Services from "../../containers/student/home/services";
import { getUserDataRequest } from "../../modules/user/Actions";
import Animation from "./animation";
import "./style.css";
const Home = () => {
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("token");
  const data = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (token) {
      dispatch(getUserDataRequest());
    }
  }, [token]);

  useEffect(() => {
    if (data?.role?.length > 0) {
      if (data?.role[0].name == "Student") {
        window.location.href = "/home";
      } else {
        window.location.href = "/dashboard/students";
      }
    }
  }, [data]);
  return (
    <PublicLayout color="white" bg="main_1">
      <Animation>
        <Header />
      </Animation>
      <Animation>
        <Services />
      </Animation>
      <Animation>
        <LatestCourses />
      </Animation>
      <Animation>
        <RegisterComp />
      </Animation>
      <Animation isHeight={false}>
        <RecordesComp />
      </Animation>
      {/* <Section>
        <SummeryComp />
      </Section> */}
      {/* <StudentComp />
      <AboutUs />

      {/* <ReviewsComp /> */}
      {/* <DownloadAppComp /> */}
      {/* <ContsctUs />
      <News /> */}{" "}
    </PublicLayout>
  );
};

export default Home;
