import { Box, Image } from "@chakra-ui/react";
import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";
const PublicLayout = ({ children, color, bg, register = true, type }) => {
  return (
    <Box
      w="100%"
      h="100%"
      minH="100vh"
      className="public"
      overflowX="hidden"
      justifyContent="space-between"
    >
      {register && (
        <Image
        src={require("../../../assets/Vector 6.jpg")}
        position="absolute"
        zIndex="-1"
        left="-150px"
        top="30%"
      /> 
      )
      }
         
      <Box h="100px">
        <Navbar color={color} bg={bg} register={register} type={type} />
      </Box>
      {register && (
        <Image
        src={require("../../../assets/Vector 6.jpg")}
        position="absolute"
        right="-150px"
        top=" 50%"
        zIndex="-1"
        display={{ base: "none", md: "block" }}
      />
      )}
      {children}

      <Footer />
    </Box>
  );
};

export default PublicLayout;
