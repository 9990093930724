import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { withTranslation } from "react-i18next";
import { RiWhatsappFill } from "react-icons/ri";

const RegisterComp = ({ t }) => {
  return (
    <Container maxW={"container.xl"} my={10} id="callCenter">
      <Text textAlign="center" fontWeight="500" fontSize="25px" mb={8}>
        Frequent questions{" "}
        <Image src={require("../../../assets/line.jpg")} m="auto" mt={3} />
      </Text>

      <Accordion
        defaultIndex={[0]}
        allowMultiple
        bg="#091e61"
        borderRadius="10px"
        p={2}
      >
        <AccordionItem border={0} my={5}>
          <h2>
            <AccordionButton
              _active={{ outline: "none" }}
              _focus={{ outline: "none" }}
              color={"white"}
            >
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight="500"
                fontSize="24px"
                color={"white"}
              >
                How to contact the platform's technical support?{" "}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} display="flex" gap={2} color={"white"}>
            Contact ASQUERA technical support through
            <a
              href="https://wa.me/+201025830160"
              target="_blank"
              style={{
                textDecoration: "underline",
                display: "flex",
                alignItems: "center",
                gap: "2px",
              }}
              rel="noreferrer"
            >
              {" "}
              <span>WhatsApp </span>
              <RiWhatsappFill color="green" fontSize="20px" />
            </a>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Container>
  );
};

export default withTranslation()(RegisterComp);
