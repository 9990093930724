export default {
    //get all STUDENTs
    GET_STUDENTS_REQUEST: 'GET_STUDENTS_REQUEST',
    GET_STUDENTS_SUCCESS: 'GET_STUDENTS_SUCCESS',
    GET_STUDENTS_FAIL: 'GET_STUDENTS_FAIL',

    GET_STUDENT_REQUEST: 'GET_STUDENT_REQUEST',
    GET_STUDENT_SUCCESS: 'GET_STUDENT_SUCCESS',
    GET_STUDENT_FAIL: 'GET_STUDENT_FAIL',

    GET_STUDENT_REPORT_REQUEST: 'GET_STUDENT_REPORT_REQUEST',
    GET_STUDENT_REPORT_SUCCESS: 'GET_STUDENT_REPORT_SUCCESS',
    GET_STUDENT_REPORT_FAIL: 'GET_STUDENT_REPORT_FAIL',


    CLEAR_SESSION_REQUEST: 'CLEAR_SESSION_REQUEST',
    CLEAR_SESSION_SUCCESS: 'CLEAR_SESSION_SUCCESS',
    CLEAR_SESSION_FAIL: 'CLEAR_SESSION_FAIL',

    //edit STUDENTs
    EDIT_STUDENT_REQUEST: 'EDIT_STUDENT_REQUEST',
    EDIT_STUDENT_SUCCESS: 'EDIT_STUDENT_SUCCESS',
    EDIT_STUDENT_FAIL: 'EDIT_STUDENT_FAIL',

    //create STUDENT
    CREATE_STUDENT_REQUEST: 'CREATE_STUDENT_REQUEST',
    CREATE_STUDENT_SUCCESS: 'CREATE_STUDENT_SUCCESS',
    CREATE_STUDENT_FAIL: 'CREATE_STUDENT_FAIL',

    DELETE_STUDENT_REQUEST: 'DELETE_STUDENT_REQUEST',
    DELETE_STUDENT_SUCCESS: 'DELETE_STUDENT_SUCCESS',
    DELETE_STUDENT_FAIL: 'DELETE_STUDENT_FAIL',

    GET_STUDENT_REQUEST: 'GET_STUDENT_REQUEST',
    GET_STUDENT_SUCCESS: 'GET_STUDENT_SUCCESS',
    GET_STUDENT_FAIL: 'GET_STUDENT_FAIL',


    RESTRICT_STUDENT_REQUEST: ' RESTRICT_STUDENT_REQUEST',
    RESTRICT_STUDENT_SUCCESS: ' RESTRICT_STUDENT_SUCCESS',
    RESTRICT_STUDENT_FAIL: ' RESTRICT_STUDENT_FAIL',

    ADD_BULK_STUDENT_REQUEST: 'ADD_BULK_STUDENT_REQUEST',
    ADD_BULK_STUDENT_SUCCESS: 'ADD_BULK_STUDENT_SUCCESS',
    ADD_BULK_STUDENT_FAIL: 'ADD_BULK_STUDENT_FAIL',

    //loading
    ADD_STUDENTS_LOADING: 'ADD_STUDENTS_LOADING',
    SUSPEND_LOADING: 'SUSPEND_LOADING',
    RESTRICT_LOADING: 'RESTRICT_LOADINGs'
};
