import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  FormLabel,
  Heading,
  Input,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { closeCreateVideos } from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import MainInput from "../../../../components/Form/input";
import Select from "react-select";
import {
  createVideoRequest,
  editVideoRequest,
  getPaymentSubjectRequest,
} from "../../../../modules/admin-modules/videos/Actions";
import { getAllChaptersRequest } from "../../../../modules/admin-modules/chapters/Actions";
import { getAllGroupsRequest } from "../../../../modules/admin-modules/groups/Actions";
import { getAllExamsRequest } from "../../../../modules/admin-modules/exams/Actions";
import dayjs from "dayjs";
import { t } from "i18next";
const AddVideo = ({ isAdd, data, setSelectedData }) => {
  const openVideo = useSelector((state) => state.model.isOpen5);
  const isLoading = useSelector((state) => state.videos.isLoading);
  const chapters = useSelector((state) => state.chapters.chapters);
  const groups = useSelector((state) => state.groups.groups);
  const paymentSubjects = useSelector((state) => state.videos.paymentSubjects);
  const exams = useSelector((state) => state.exams.exams);

  const [totalPlayed, setTotalPlayed] = useState(false);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data
      ? { ...data, title: data?.title }
      : { publish: 0, auto_controlled: 1 },
    shouldUnregister: true,
  });

  const groupsOptions = groups?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const chaptersOptions = chapters?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const paymentSubjectsOptions = paymentSubjects?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const examsOptions = exams?.map((data) => {
    return { value: data.id, label: data.name };
  });

  useEffect(() => {
    if (openVideo) {
      if (chapters.length == 0) dispatch(getAllChaptersRequest());
      if (groups.length == 0) dispatch(getAllGroupsRequest());
      dispatch(getPaymentSubjectRequest());
      dispatch(getAllExamsRequest());
    }
  }, [openVideo]);
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? "اضافة فيديو " : "تعديل فيديو"}
      </Heading>
    </Box>
  );
  const onSubmit = async (values) => {
    const newDate = dayjs(data?.expiry_date).format("YYYY-MM-DD");

    const action = () => {
      dispatch(closeCreateVideos());
      reset();
      setTotalPlayed(false);
      setSelectedData(() => {});
    };
    if (isAdd) {
      let formData = new FormData();
      formData.append("expiry_date", newDate);
      for (let key in values) {
        if (key == "groups") {
          values[key]?.map((val, idx) =>
            formData.append(`groups[${idx}]`, val)
          );
        } else {
          formData.append(key, values[key]);
        }
      }
      dispatch(createVideoRequest(formData, action));
    } else {
      let formData = new FormData();
      formData.append("_method", "PUT");
      const totel = { ...data, ...values };
      for (let key in totel) {
        if (key == "groups") {
          totel[key]?.map((val, idx) =>
            formData.append(`groups[${idx}]`, val?.id ? val?.id : val)
          );
        } else {
          formData.append(key, totel[key]);
        }
      }
      dispatch(
        editVideoRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeCreateVideos());
    setSelectedData(() => {});
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openVideo}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          dir="rtl"
          w="100%"
          flexWrap="wrap"
          justifyContent="space-between"
          gap="5"
        >
          <Box w="45%">
            <MainInput
              label=" عنوان الفيديو"
              defaultValues={data?.title}
              error={errors?.title?.message}
              placeholder="  اكتب عنوان الفيديو"
              register={register}
              name="title"
            />
          </Box>
          <Box w="45%">
            <MainInput
              type="datetime-local"
              label=" تاريخ انتهاء الفيديو"
              defaultValues={data?.expiry_date}
              error={errors?.expiry_date?.message}
              placeholder="  ادخل تاريخ انتهاء الفيديو"
              register={register}
              name="expiry_date"
            />
          </Box>
          <Box w="45%">
            <MainInput
              label=" اضافة رابط للفيديو"
              defaultValues={data?.url}
              error={errors?.url?.message}
              placeholder="  اضافة رابط للفيديو"
              register={register}
              name="url"
            />
          </Box>
          <Box w="45%">
            <FormLabel> نبذة عن المحتوى : </FormLabel>
            <Textarea
              bg="#f5f5f5"
              defaultValue={data?.over_view}
              placeholder="اكتب نبذة مبسطة عن المحتوى"
              {...register("over_view", { required: false })}
              name="over_view"
            ></Textarea>
          </Box>
          <Box w="45%">
            <Box w="100%">
              <FormLabel>الفصول : </FormLabel>
              <Select
                options={chaptersOptions}
                defaultValue={{
                  label: data?.chapter?.name,
                  value: data?.chapter?.id,
                }}
                onChange={(data) => setValue("chapter_id", data.value)}
                styles={colourStyles}
                placeholder="الصف"
              />
            </Box>
          </Box>
          <Box w="45%">
            <Box w="100%">
              <FormLabel>حصة الدفع : </FormLabel>
              <Select
                options={paymentSubjectsOptions}
                defaultValue={{
                  label: data?.payment_subject_?.name,
                  value: data?.payment_subject_?.id,
                }}
                onChange={(data) => setValue("payment_subject_id", data.value)}
                styles={colourStyles}
                placeholder="payment subject"
              />
            </Box>
          </Box>
          <Box w="45%">
            <Box w="100%">
              <FormLabel>المجموعات : </FormLabel>
              <Select
                options={groupsOptions}
                isMulti={true}
                defaultValue={data?.groups?.map((val) => {
                  return { label: val?.name, value: val?.id };
                })}
                onChange={(data) => {
                  const selectedGroups = data.map((val) => val.value);
                  setValue("groups", selectedGroups);
                }}
                styles={colourStyles}
                placeholder="المجموعات"
              />
            </Box>
          </Box>
          <Box w="45%">
            <FormLabel>نوع الفيديو : </FormLabel>

            <Stack
              direction="row"
              role="group"
              aria-labelledby="my-radio-group"
            >
              <label>
                <input
                  {...register("video_type", { required: true })}
                  type="radio"
                  value="0"
                  defaultChecked={data?.video_type == 0 ? true : false}
                />
                vimeo
              </label>
              <label>
                <input
                  {...register("video_type", { required: true })}
                  type="radio"
                  value="1"
                  defaultChecked={data?.video_type == 1 ? true : false}
                />
                vdo
              </label>
            </Stack>
          </Box>
          <Box w="45%">
            <FormLabel>مكان الفيديو : </FormLabel>

            <Stack
              direction="row"
              role="group"
              aria-labelledby="my-radio-group"
            >
              <label>
                <input
                  {...register("type", { required: true })}
                  type="radio"
                  value="1"
                  defaultChecked={data?.type == 1 ? true : false}
                />
                asquera
              </label>
              <label>
                <input
                  {...register("type", { required: true })}
                  type="radio"
                  value="7"
                  defaultChecked={data?.type == 7 ? true : false}
                />
                Asquera Practice
              </label>
            </Stack>
          </Box>
          <Box w="45%">
            <FormLabel>نشر الفيديو : </FormLabel>
            <Stack
              direction="row"
              role="group"
              aria-labelledby="my-radio-group"
            >
              <label>
                <input
                  {...register("publish", { required: true })}
                  type="radio"
                  value="0"
                  defaultChecked={data?.publish == 0 ? true : false}
                />
                عدم نشر
              </label>
              <label>
                <input
                  {...register("publish", { required: true })}
                  type="radio"
                  value="1"
                  defaultChecked={data?.publish == 1 ? true : false}
                />
                نشر
              </label>
            </Stack>
          </Box>
          <Box w="45%">
            <FormLabel> حالة الفيديو : </FormLabel>
            <Stack
              direction="row"
              role="group"
              aria-labelledby="my-radio-group"
            >
              <label>
                <input
                  {...register("related_to_homework", { required: true })}
                  type="radio"
                  value="1"
                  defaultChecked={data?.related_to_homework == 1 ? true : false}
                />
                يحتوي علي واجب
              </label>
              <label>
                <input
                  {...register("related_to_homework", { required: true })}
                  type="radio"
                  value="0"
                  defaultChecked={data?.related_to_homework == 0 ? true : false}
                />
                لا يحتوي علي واجب
              </label>
            </Stack>
          </Box>
          <Box w="45%">
            <FormLabel> مشاهدات الفيديو : </FormLabel>
            <Stack
              direction="row"
              role="group"
              aria-labelledby="my-radio-group"
            >
              <label>
                <input
                  {...register("auto_controlled", { required: true })}
                  type="radio"
                  value="0"
                  defaultChecked={data?.auto_controlled == 0 ? true : false}
                  onChange={() => setTotalPlayed(false)}
                />
                not limited
              </label>
              <label>
                <input
                  {...register("auto_controlled", { required: true })}
                  type="radio"
                  value="1"
                  defaultChecked={data?.auto_controlled == 1 ? true : false}
                  onChange={() => setTotalPlayed(false)}
                />
                limited views
              </label>
              <label>
                <input
                  {...register("auto_controlled", { required: true })}
                  type="radio"
                  onChange={() => setTotalPlayed(true)}
                />
                controlled views
              </label>
            </Stack>

            <br />
            {totalPlayed && (
              <Input
                {...register("auto_controlled", { required: true })}
                bg="#f5f5f5"
                defaultValue={data?.total_played}
                type="number"
                placeholder="full play count"
              />
            )}
          </Box>
          <Box w="50%">
            <FormLabel>الامتحان الذي بعده : </FormLabel>
            <Select
              options={examsOptions}
              defaultValue={{
                label: data?.name,
                value: data?.id,
              }}
              onChange={(formData) => setValue("post_exam_id", formData.value)}
              styles={colourStyles}
              placeholder="الامتحان الذي بعده"
            />
          </Box>

          <Box w="100%">
            <MainInput
              label="video key"
              defaultValues={data?.vdo_account_key}
              error={errors?.vdo_account_key?.message}
              placeholder="   "
              register={register}
              name="vdo_account_key"
            />
          </Box>
        </Flex>
      </form>
    </MainModel>
  );
};

export default AddVideo;
