import { Container, Flex, Text, chakra } from "@chakra-ui/react";
import React from "react";

import { withTranslation } from "react-i18next";
const Header = ({ t }) => {
  return (
    <Flex
      justifyContent="space-between"
      color="white"
      id="home"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      position="relative"
    >
      <Container maxW={"container.xl"}>
        <Flex
          alignItems={"center"}
          justifyContent="center"
          flexDir="column"
          color="black"
          my={10}
        >
          <Text
            fontSize={{ base: "30px", md: "50px" }}
            fontWeight="700"
            mb="1.25rem"
          >
            Your first step towards{" "}
            <chakra.span color="main_2">Success</chakra.span>
          </Text>
          <Text textAlign="center" fontSize={{ base: "16px", md: "20px" }}>
            It is a platform that aims to develop the educational process to
            help the student achieve...
            <br />
            Success and excellence in all subjects This is through the services
            we provide.
          </Text>
        </Flex>
      </Container>
    </Flex>
  );
};

export default withTranslation()(Header);
