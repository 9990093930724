import {
  Box,
  Button,
  FormLabel,
  Heading,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainModel from "../../../components/Models/main-model";
import { closeAdminHomeworkCommentModel } from "../../../modules/admin-modules/models/Actions";
import { commentStuentHomeworkRequest } from "../../../modules/admin-modules/reports/Actions";
const HomeworkComment = ({ data, homeworkId }) => {
  const openHomeworkComment = useSelector((state) => state.model.isOpen27);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px"></Heading>
    </Box>
  );
  const handelAddComment = () => {
    dispatch(
      commentStuentHomeworkRequest({
        id: data?.id,
        body: {
          homework_id: homeworkId,
          student_id: data?.student?.id,
          evaluation: comment,
        },
      })
    );
    dispatch(closeAdminHomeworkCommentModel());
  };
  const closeFunc = () => {
    dispatch(closeAdminHomeworkCommentModel());
  };
  return (
    <MainModel
      isLoading={false}
      header={header}
      closeFunc={closeFunc}
      openVar={openHomeworkComment}
      buttonTitle={"حفظ التغيرات"}
      buttonFunc={handelAddComment}
    >
      <VStack dir="rtl">
        <Button
          as="a"
          href={`https://api.la-mer1.com/public/homework/${data?.file}`}
          target="_blank"
        >
          الحل
        </Button>

        <Box w="100%">
          <FormLabel> الملاحظات : </FormLabel>
          <Textarea
            bg="#f5f5f5"
            placeholder="اكتب الملاحظات"
            onChange={(e) => setComment(e.target.value)}
            name="homework"
          ></Textarea>
        </Box>
      </VStack>
    </MainModel>
  );
};

export default HomeworkComment;
