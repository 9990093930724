import { Container, Image, Stack, Text } from "@chakra-ui/react";
import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Student1 from "../../../assets/student-1.jpg";
import Student2 from "../../../assets/student-2.jpg";
import Student3 from "../../../assets/student-3.png";
import Student4 from "../../../assets/student-4.jpg";

const LatestCourses = ({ t }) => {
  function SampleNextArrow(props) {
    return (
      <div
        style={{
          display: "flex",
          background: "#9A101C",
          width: "50px",
          height: "40px",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          marginTop: 0,
        }}
        onClick={gotoNext}
      >
        <IoMdArrowForward color="white" fontWeight="700" fontSize="20px" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    return (
      <div
        style={{
          display: "flex",
          background: "#9A101C",
          width: "50px",
          height: "40px",

          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
        onClick={gotoPrev}
      >
        <IoMdArrowBack color="white" fontWeight="700" fontSize="20px" />
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };
  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <Container maxW={"container.xl"} my={16} id="subjects" position="relative">
      <Text textAlign="center" fontWeight="500" fontSize="25px" mb={8}>
        Students Gallery
        <Image src={require("../../../assets/line.png")} m="auto" mt={3} />
      </Text>

      <Slider {...settings} ref={sliderRef}>
        <Image src={Student1} width="400px" h="200px" p={4} />

        <Image src={Student2} width="400px" h="200px" p={4} />

        <Image src={Student3} width="400px" h="200px" p={4} />

        <Image src={Student4} width="400px" h="200px" p={4} />
        <Image src={Student2} width="400px" h="200px" p={4} />
      </Slider>
      <Stack
        flexDir="row"
        gap={6}
        position="absolute"
        top="65%"
        left="45%"
        display={{ base: "none", md: "flex" }}
      >
        <SamplePrevArrow />

        <SampleNextArrow />
      </Stack>
    </Container>
  );
};

export default withTranslation()(LatestCourses);
