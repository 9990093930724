import { Container, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { withTranslation } from "react-i18next";
import { FiFileText } from "react-icons/fi";
import { GiTeacher } from "react-icons/gi";
import { RiFileCopy2Line } from "react-icons/ri";

const RecordesComp = ({ t }) => {
  const data = [
    {
      icon: <GiTeacher fontSize="38px" color="#9A101C" />,
      title: "Regular follow-up",
      desc: "Follow up with the student to prevent the accumulation of classes",
    },
    {
      icon: <FiFileText fontSize="38px" color="#9A101C" />,
      title: "Exams and training",
      desc: " Periodic exams to train the student for the end-of-year exam",
    },
    {
      icon: <RiFileCopy2Line fontSize="38px" color="#9A101C" />,
      title: "Largest question bank",
      desc: " The largest question bank in all subjects from all sources",
    },
  ];
  return (
    <Container maxW={"container.xl"} my="10rem">
      <Text textAlign="center" fontWeight="500" fontSize="25px" mb={3}>
        Platform features to facilitate your studies{" "}
        <Image src={require("../../../assets/line.jpg")} m="auto" mt={3} />
      </Text>

      <Stack flexDir="row" flexWrap="wrap" gap={5} mt={8}>
        {data?.map((elem, index) => {
          return (
            <Stack
              bg="#091e61"
              borderRadius="20px"
              align="center"
              key={index}
              py="60px"
              px="20px"
              w={{ base: "100%", md: "32%" }}
              textAlign="center"
              mt={2}
              boxShadow="0px 1px 4px #8080804d"
              color={"white"}
            >
              {elem?.icon}

              <Text fontSize="18px" fontWeight="500" fontFamily="Cairo">
                {elem?.title}
              </Text>
              <Text color="white" fontSize="14px" fontFamily="Cairo">
                {elem?.desc}
              </Text>
            </Stack>
          );
        })}
      </Stack>
    </Container>
  );
};

export default withTranslation()(RecordesComp);
