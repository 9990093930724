export const addVideo = async ({
  loadVideo,
  otp,
  playbackInfo,
  videoContainerRef,
  setVideoRef,
  setDisplay,
}) => {
  videoContainerRef.current.classList.add("haveVideo");
  const video = loadVideo({
    otp,
    playbackInfo,
    container: videoContainerRef.current,
    configuration: {
      // autoplay: true
    },
  });
  setVideoRef(video);
  setDisplay("none");
};

export const removeVideo = ({ videoContainerRef, videoRef, setVideoRef }) => {
  videoRef?.remove();
  setVideoRef(null);
  videoContainerRef.current.classList.remove("haveVideo");
};
