import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { RiAddFill, RiFileExcel2Line, RiSearch2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { openCreateStudent } from "../../../modules/admin-modules/models/Actions";
import { addBulkStudentRequest } from "../../../modules/admin-modules/students/Actions";

const SearchBar = ({ setAdd, setSelectedData, setSearchTerm }) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.students.count);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "white",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  return (
    <VStack w="100%" my={{ base: "50px", md: "30px" }}>
      <Flex
        alignItems="center"
        w="100%"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Text
          fontSize="24px"
          fontWeight="600"
          color="main_1"
          width={{ base: "100%", md: "20%" }}
          mb={{ base: "10px", md: 0 }}
        >
          الطلاب
        </Text>

        <Flex alignItems="center" flexWrap="wrap" gap={2}>
          <Button
            borderRadius="16px"
            leftIcon={<RiAddFill />}
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openCreateStudent());
            }}
          >
            إضافة طالب
          </Button>
          <Button
            borderColor="main_1"
            border="1px solid"
            mx="10px"
            borderRadius="16px"
            leftIcon={<RiFileExcel2Line />}
            color="main_1"
            bg="white"
            _focus={{ outline: "none" }}
            position="relative"
          >
            <a href="https://api.la-mer1.com/api/admin/students/export_bulk/1">
              تصدير الى Excel
            </a>
          </Button>
          <Button
            borderColor="main_1"
            border="1px solid"
            mx="10px"
            borderRadius="16px"
            leftIcon={<RiFileExcel2Line />}
            color="main_1"
            bg="white"
            _focus={{ outline: "none" }}
            position="relative"
          >
            <Text>Add Students in Bulk</Text>
            <Input
              onChange={(e) => {
                let formData = new FormData();
                formData.append("student_bulk", e.target.files[0]);
                dispatch(addBulkStudentRequest(formData));
              }}
              type="file"
              placeholder="اختر ملف ليتم رفعه"
              opacity="0"
              position="absolute"
              w="100%"
              h="100%"
            />
          </Button>

          <Box display={{ base: "none", md: "block" }}>
            <LogoutMenu />
          </Box>
        </Flex>
      </Flex>

      {/* <Text w="100%" fontSize="20px" textAlign="right">
        عدد الطلاب :{count}{" "}
      </Text> */}

      <HStack
        w="100%"
        justifyContent="space-between"
        bg="#f5f5f5"
        mt="40px !important"
        p="10px"
        mb="-30px !important"
      >
        <InputGroup w="400px">
          <InputLeftElement
            pointerEvents="none"
            children={<RiSearch2Fill color="main_1" fontSize="20px" />}
          />
          <Input
            type="text"
            placeholder="ابحث باسم الطالب أو رقم الموبايل"
            borderRadius="16px"
            bg="white"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </HStack>
    </VStack>
  );
};

export default SearchBar;
