import {
  Container,
  HStack,
  Image,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";
import React from "react";
import { withTranslation } from "react-i18next";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Teacher from "../../../assets/teacher.png";

const Services = ({ t }) => {
  return (
    <Container maxW="6xl" width="100%">
      <HStack width="100%">
        <Image src={Teacher} width="50%" />
        <VStack alignItems="start" gap={4}>
          <Text fontSize="34px" color="black" fontWeight="500" m={0}>
            <chakra.span color="main_2">Lamer </chakra.span>
            was founded by Monsieur
            <br />
            Abdo Farag
          </Text>
          <Text color="black" fontSize="14px">
            An expert teacher in the French language for all secondary levels
            <br />
            More than 19 years of experience in the field of teaching in public
            and private schools, and the owner and author of the book
            “Spirituality in the French Language”
          </Text>
        </VStack>
      </HStack>
    </Container>
  );
};

export default withTranslation()(Services);
