import axiosInstance from "../../../helpers/api";

export const getAllStudents = (formData) => {
    return axiosInstance.get(`/admin/students?page=${formData?.payload}&name=${formData?.searchTerm}`);
};

export const clearSession = (id) => {
    return axiosInstance.get(`/admin/students/clear_session/${id}`);
};


export const editStudents = (formData) => {

    return axiosInstance.post(`/admin/students/${formData.id}`, formData.formData);
};


export const createStudents = (formData) => {
    return axiosInstance.post('/admin/students', formData);
};

export const getStudent = (id) => {
    return axiosInstance.get(`/admin/students/${id}`);
};

export const deleteStudent = (id) => {
    return axiosInstance.delete(`/admin/students/${id}`);
};


export const getStudentReport = (id) => {
    return axiosInstance.get(`/admin/Students_info/${id}`);
};
export const restricStudent = (data) => {
    return axiosInstance.post(`/admin/students/restrict/${data?.id}`, data.formData);
};

export const addBulkStudent = (formData) => {
    return axiosInstance.post(`/admin/students/add_bulk`, formData);
};
