import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Image,
  Stack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { withTranslation } from "react-i18next";
import { HiMenuAlt2 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
const MobileNavbar = ({ t, i18n }) => {
  const routes = [
    { label: "الرئيسية", link: "/" },
    { label: "المدرسين", link: "#teachers" },
    { label: "المواد", link: "#subjects" },
    { label: "الدعم الفني", link: "#callCenter" },
  ];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const navigate = useNavigate();

  return (
    <>
      <Button
        ref={btnRef}
        colorScheme="teal"
        onClick={onOpen}
        display={{ base: "flex", lg: "none" }}
        alignItems="center"
        as={IconButton}
        aria-label="Options"
        icon={<HiMenuAlt2 />}
        variant="outline"
        color="white"
        border="0"
        _hover={{
          background: "rgb(32, 34, 37)",
        }}
        bg="rgb(32, 34, 37)"
      >
        Open
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            {" "}
            <Stack alignItems="center">
              <Image
                src={require("../../../assets/logo.png")}
                w="200px"
                id="logo"
                h="auto"
                mx="-10px"
                onClick={() => navigate("/")}
              />
            </Stack>
          </DrawerHeader>

          <DrawerBody>
            <VStack p="5px">
              {routes?.map((elem, index) => {
                return (
                  <a key={index} href={elem.link}>
                    {elem?.label}
                  </a>
                );
              })}

              <Button
                onClick={() => navigate("/login")}
                borderColor="main_2"
                bg="main_2"
                textTransform={"capitalize"}
                color="white"
              >
                تسجيل دخول
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default withTranslation()(MobileNavbar);
