import {
  Box,
  HStack,
  Icon,
  SkeletonCircle,
  SkeletonText,
  Text,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import {
  RiBookmarkLine,
  RiCalendarTodoFill,
  RiTimerLine,
  RiTvLine,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getVideoByIdRequest } from "../../../modules/students-modules/videos/Actions";
import Player from "../../vdoPlayer/player";

const VideoPreview = ({ data, loading, otp, playBack }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const videoDetails = useSelector((state) => state.chapterVideos.videoDetails);
  const userData = useSelector((state) => state.user.userData);

  const video = data ? data : videoDetails;
  const videoGroups = video?.groups?.map((val) => val?.id);
  const date1 = dayjs(video?.expiry_date);
  const diff = date1.diff(video?.created_at, "day");
  // const playbackInfo = useSelector((state) => state.chapterVideos.playbackInfo);
  const seenLoading = useSelector((state) => state.chapterVideos.seenLoading);

  useEffect(() => {
    if (!data) dispatch(getVideoByIdRequest(id));
  }, [data]);

  return (
    <VStack
      bg="main_1"
      borderRadius="8px"
      p="20px"
      color="white"
      w="100%"
      h="100%"
      alignItems="flex-start"
      spacing="15px"
      position="relative"
    >
      {/* title */}
      <Text fontSize="28px" fontWeight="500">
        {video?.title}
      </Text>

      {/* video */}
      <Box bg="gray" w="100%" h="100%" alignSelf="center" borderRadius="8px">
        {video?.views_count?.views_count > video?.max_allowed_views ||
        (video?.suspended == 1 && video?.user_video_suspended != 0) ? (
          <Text textAlign="center" p={4} fontSize="20px">
            You have exceeded your views
          </Text>
        ) : !videoGroups?.includes(userData?.group?.id) ? (
          <Text textAlign="center" p={4} fontSize="20px">
            Not available to your group
          </Text>
        ) : video?.video_type == 0 ? (
          <div dangerouslySetInnerHTML={{ __html: video?.url }}></div>
        ) : loading ? null : (
          <Player otp={otp} playbackInfo={playBack} />
        )}
      </Box>

      {/* options */}
      {seenLoading ? (
        <HStack w="100%" bg="transparent">
          <SkeletonCircle size="6" startColor="gray.100" endColor="gray.600" />
          <SkeletonText
            startColor="gray.100"
            endColor="gray.600"
            w="80%"
            mt="4"
            noOfLines={1}
            spacing="4"
          />
        </HStack>
      ) : (
        <HStack
          justifyContent="space-between"
          w="100%"
          fontSize={{ base: "10px", lg: "18px" }}
        >
          <HStack spacing={{ base: "5px", lg: "50px" }}>
            <HStack spacing="8px" w="100%">
              <Icon as={RiCalendarTodoFill} />
              <Text w="fit-content">
                {dayjs(video?.created_at).format("D , MMM ,YYYY")}
              </Text>
            </HStack>
            {diff && (
              <HStack spacing="8px" w="100%">
                <Icon as={RiTimerLine} />
                <Text w="fit-content">{diff} Days remaining</Text>
              </HStack>
            )}
            {video?.views_count && (
              <HStack spacing="8px" w="100%">
                <Icon as={RiTvLine} />
                <Text w="fit-content">{`Watch ${
                  video?.max_allowed_views -
                    (video?.views_count?.views_count > video?.max_allowed_views
                      ? video?.max_allowed_views
                      : video?.views_count?.views_count) || 0
                } more times (${
                  video?.views_count?.views_count
                    ? video?.views_count?.views_count > video?.max_allowed_views
                      ? video?.max_allowed_views
                      : video?.views_count?.views_count
                    : 0
                }/${
                  video?.max_allowed_views ? video?.max_allowed_views : 0
                })`}</Text>
              </HStack>
            )}
          </HStack>

          <Icon as={RiBookmarkLine} display={{ base: "none", lg: "block" }} />
        </HStack>
      )}
    </VStack>
  );
};

export default VideoPreview;
