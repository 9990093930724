/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import MainInput from "../../../../components/Form/input";
import MainModel from "../../../../components/Models/main-model";
import {
  createChapterRequest,
  editChapterRequest,
} from "../../../../modules/admin-modules/chapters/Actions";
import { closeCreateChapterModel } from "../../../../modules/admin-modules/models/Actions";
import { getAllAdminSectionsRequest } from "../../../../modules/admin-modules/sections/Actions";
import { getAllSubjectsRequest } from "../../../../modules/admin-modules/subjects/Actions";

const AddChapter = ({ isAdd, data, setSelectedData }) => {
  const [selected, setSelected] = useState("");
  const openChapter = useSelector((state) => state.model.isOpen11);
  const subjects = useSelector((state) => state.subjects.subjects);
  const isLoading = useSelector((state) => state.chapters.isLoading);
  const sections = useSelector((state) => state.adminSections.sections);

  const subjectsOptions = subjects?.map((data) => {
    return { value: data.id, label: data.name };
  });
  const sectionsOptions = sections?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (openChapter) {
      dispatch(getAllSubjectsRequest());
      dispatch(getAllAdminSectionsRequest());
    }
  }, [openChapter]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: !isAdd ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? " إضافة الفصل" : "تعديل  الفصل"}
      </Heading>
    </Box>
  );
  const onSubmit = async (values) => {
    const action = () => {
      dispatch(closeCreateChapterModel());
      setSelectedData({});
      reset();
    };

    if (isAdd) {
      let formData = await new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }
      if (values.subject_id) {
        await dispatch(createChapterRequest(formData, action));
      } else {
        toast.error("اختر المادة");
      }
    } else {
      let formData = await new FormData();
      formData.append("_method", "PUT");

      const totel = { ...data, ...values };
      for (let key in totel) {
        formData.append(key, totel[key]);
      }

      dispatch(
        editChapterRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };
  const closeFunc = () => {
    dispatch(closeCreateChapterModel());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openChapter}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          dir="rtl"
          w="100%"
          flexWrap="wrap"
          justifyContent="space-between"
          gap="4"
        >
          <Box w="45%">
            <MainInput
              label="اسم الفصل باللغة الانجليزية"
              defaultValues={data?.name}
              error={errors?.name?.message}
              placeholder="ادخل اسم الفصل باللغة الانجليزية"
              register={register}
              name="name"
            />
          </Box>
          <Box w="45%">
            <MainInput
              label="اسم الفصل باللغة العربية"
              defaultValues={data?.name_ar}
              error={errors?.name_ar?.message}
              placeholder="ادخل اسم الفصل باللغة العربية"
              register={register}
              name="name_ar"
            />
          </Box>
          <Box w="45%">
            <FormLabel>رفع الصورة:</FormLabel>
            <Button
              bg="main_1"
              color="white"
              borderRadius="8px"
              textAlign="center"
              position="relative"
              w="100%"
            >
              <Text>{selected ? selected : "اختر ملف ليتم رفعه"} </Text>
              <Input
                onChange={(e) => {
                  setValue("logo", e.target.files[0]);
                  setSelected((s) => e.target.files[0].name);
                }}
                type="file"
                placeholder="اختر ملف ليتم رفعه"
                opacity="0"
                position="absolute"
                w="100%"
                h="100%"
              />
            </Button>
          </Box>

          <Box w="45%">
            <Box w="100%">
              <FormLabel>المادة : </FormLabel>
              <Select
                options={subjectsOptions}
                onChange={(data) => setValue("subject_id", data.value)}
                styles={colourStyles}
                placeholder="المادة"
                defaultValue={subjects?.map((val) => {
                  if (val?.id == data?.subject_id)
                    return { label: val.name, value: val.id };
                })}
              />
            </Box>
          </Box>
          <Box w="45%">
            <Box w="100%">
              <FormLabel>الاقسام : </FormLabel>
              <Select
                options={sectionsOptions}
                onChange={(data) => setValue("section_id", data.value)}
                styles={colourStyles}
                placeholder="الاقسام"
                defaultValue={sections?.map((val) => {
                  if (val?.id == data?.section_id)
                    return { label: val.name, value: val.id };
                })}
              />
            </Box>
          </Box>
        </Flex>
      </form>
    </MainModel>
  );
};

export default AddChapter;
