import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { theme } from "./chakra.config";
import Routers from "./components/Routes";
import { getUserDataRequest } from "./modules/user/Actions";

function App() {
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("token");
  useEffect(() => {
    if (token) dispatch(getUserDataRequest());
  }, [token]);
  return (
    <ChakraProvider theme={theme}>
      <ToastContainer />
      <Routers />
    </ChakraProvider>
  );
}

export default App;
