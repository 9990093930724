import React, { useRef, useState } from "react";
import useVdocipher from "../hooks/use-vdocipher";
import VideoStatusUsingAPI from "../status/index";
import { addVideo, removeVideo } from "./func";
import "./style.css";
import { AiOutlinePlayCircle } from "react-icons/ai";
export default function Player({ message, otp, playbackInfo }) {
  const { loadVideo, isAPIReady } = useVdocipher();
  const videoContainerRef = useRef();
  const [videoRef, setVideoRef] = useState(null);
  //console.log(otp, playbackInfo);
  const [display, setDisplay] = useState("block");
  return (
    <div className="hello" style={{ position: "relative" }}>
      <div
        className="inline"
        style={{ position: "absolute", zIndex: "10", top: "30%" }}
      >
        <br />
        <button
          style={{
            // background: "white",
            color: "white",
            borderRadius: "8px",
            padding: "10px",
            margin: "2px",
            width: "100px",
            display: display,
          }}
          onClick={() =>
            addVideo({
              loadVideo,
              otp,
              playbackInfo,
              videoContainerRef,
              setVideoRef,
              setDisplay,
            })
          }
        >
          <AiOutlinePlayCircle style={{ width: "100%", height: "100%" }} />
        </button>
      </div>
      <div className="vdo-container" ref={videoContainerRef}></div>
      <VideoStatusUsingAPI videoRef={videoRef} isAPIReady={isAPIReady} />
    </div>
  );
}
