import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    transparent: "transparent",
    gradient_main_color:
      "#091e61",
    main_1:
      "radial-gradient(rgb(8, 96, 168) 0%, rgb(10, 17, 82) 100%)",
    main_1_light: "#f7d7237d",
    main_2: "#9A101C",
    main_font: "#888f91",
  },

  fonts: {
    heading: "Baloo 2, cursive",
    body: "Baloo 2, cursive",
  },
});
