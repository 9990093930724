import { Box, Button, Container, Flex, HStack, Image, Text} from "@chakra-ui/react";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MobileNavbar from "./mobileNavbar";

const Navbar = ({ color, register, t, i18n, type }) => {
  const navigate = useNavigate();
  const routes = [
    { label: "Contact us", link: "#callCenter" },
    { label: "Subjects", link: "#subjects" },
    { label: "Teachers", link: "#teachers" },

    { label: "Home", link: "/" },
  ];

  return (
    <Box
      w="100%"
      position="fixed"
      zIndex="100"
      top="0"
      color={color}
      backdropBlur="lg"
      id="navbar"
      py="20px"
      bg="radial-gradient(rgb(8, 96, 168) 0%, rgb(10, 17, 82) 100%)"
    >
      <Container
        maxW={"container.xl"}
        w="100%"
        className="fixed top-0 left-0  z-50 w-full py-5 bg-[#e4e8ebd9] backdrop-blur-lg"
      >
        <HStack
          alignItems="center"
          p="5px"
          w="100%"
          justifyContent={"space-between"}
          textTransform={"capitalize"}
          h="50px"
        >
          <Flex
            display={{ base: "none", lg: "flex" }}
            visibility={register ? "visible" : "hidden"}
            flexDir="row"
            w="100%"
            gap={5}
            alignItems="center"
          >
            <Button
              onClick={() => navigate("/login")}
              borderColor="main_2"
              bg="main_2"
              textTransform={"capitalize"}
              color="white"
              _hover={{ bg: "white", borderColor: "main_2", border:"2px" ,color: "main_2" }}
            >
              Login{" "}
            </Button>
            {routes?.map((elem, index) => {
              return (
                <a key={index} href={elem.link} style={{ color: "white"}}>
                  {elem?.label}
                </a>
              );
            })}
          </Flex>

          <Box display={{ base: "block", lg: "none" }}>
            <MobileNavbar />
          </Box>
          <Box>
            <Image
              src={require("../../../assets/logo.png")}
              w="200px"
              id="logo"
              h="auto"
              mx="-10px"
              bg="white"
              borderRadius={20}
              onClick={() => navigate("/")}
            />
          </Box>
          <Text display={{ base: "none", lg: "block" }} color="white">
            Powered by Asquera {" "}
          </Text>
        </HStack>
      </Container>
    </Box>
  );
};

export default withTranslation()(Navbar);
